import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames"; // Used to toggle active class
import InsightsTab from "./insights/insights-tab";
import FileInformationTab from "./file-information/file-information-tab";
import HistoryTab from "./history/history-tab";

const DetailsTabs = ({ currentFile, fileDetails, userId, orgId }) => {
  const [activeTab, setActiveTab] = useState("1"); // Default to the first tab
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="tabs-antstrap">
      <Nav tabs className="no-select">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            Insights
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggleTab("2")}
          >
            File Information
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggleTab("3")}
          >
            History
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <InsightsTab
            key={currentFile._id}
            file={fileDetails}
            userId={userId}
            orgId={orgId}
          />
        </TabPane>
        <TabPane tabId="2">
          <FileInformationTab key={currentFile._id} file={currentFile} />
        </TabPane>
        <TabPane tabId="3">
          <HistoryTab key={currentFile._id} file={currentFile} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DetailsTabs;
