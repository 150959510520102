import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFileDetailsAction,
  getCurrentFileDetails,
  getCurrentFileUserDetails,
} from "../../redux/slices/active-folder-slice";
import { getRelativeTime } from "../../utils/date-utils";
import TooltipCustom from "../common/tooltip-custom";
import DetailsTabs from "./tabs/details-tabs";

const ImageDetailsPanel = ({ currentFile }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(getCurrentFileUserDetails);
  const fileDetails = useSelector(getCurrentFileDetails);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      fetchFileDetailsAction({
        fileId: currentFile._id,
        orgId: currentFile.org_id,
        language: currentFile.lang,
      })
    );
  }, [currentFile]);

  const timeLabel = currentFile.is_bin ? "Deleted" : "Created";
  const timeValue = currentFile.is_bin
    ? currentFile.deleted_at
    : currentFile.created_at;

  return (
    <>
      <div className="image-viewer-right">
        <div className="image-details">
          <strong>
            {currentFile
              ? currentFile?.user?.first_name +
                " " +
                currentFile?.user?.last_name
              : "Unknown"}
          </strong>
          <p id="time-info">
            {timeLabel} {getRelativeTime(timeValue)}
          </p>
        </div>
        <DetailsTabs
          currentFile={currentFile}
          fileDetails={fileDetails}
          userId={user.id}
          orgId={currentFile.org_id}
        />
      </div>
      <TooltipCustom text={timeValue} target="time-info" />
    </>
  );
};

export default ImageDetailsPanel;
