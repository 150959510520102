import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyState from "../components/common/empty-state";
import FileThumbnails from "../components/folders/file-thumbnails";
import FolderThumbnails from "../components/folders/folder-thumbnails";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  getSeachResultFiles,
  getSeachResultfolders,
  searchFilesAction,
} from "../redux/slices/search-slice";

const HomeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const organization = useSelector((state) => state.organization);
  const searchFiles = useSelector(getSeachResultFiles);
  const searchFolders = useSelector(getSeachResultfolders);

  useEffect(() => {
    // By default, set a date to display suggestions
    const queryParams = new URLSearchParams(location.search);
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    queryParams.set("after", getFormattedDate(sevenDaysAgo));
    navigate(`${location.pathname}?${queryParams.toString()}`);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const afterParam = queryParams.get("after");

    if (afterParam && organization.id) {
      setIsLoading(true);
      dispatch(
        searchFilesAction({
          filters: {
            searchKey: "",
            type: "all",
            tags: [],
            startDate: afterParam,
            endDate: null,
            org_id: organization.id,
            language: "marathi",
          },
          successHandler: () => {
            setIsLoading(false);
          },
        })
      );
    }
  }, [location, organization.id]);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <StorageNavbar filters={["tags", "type", "date"]} />
      <div className="storage-content">
        {!isLoading ? (
          (searchFiles && searchFiles.length) ||
          (searchFolders && searchFolders.length) ? (
            <>
              <FolderThumbnails folders={searchFolders} />
              <FileThumbnails files={searchFiles} />
            </>
          ) : (
            <EmptyState text={"Nothing found."} />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default HomeView;
