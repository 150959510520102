import React from "react";
import { formatFileSize } from "../../../../utils/file-utils";

const FileInformationTab = ({ file }) => {
  return (
    <>
      <div className="tab-content">
        <div>
          <div className="tab-section-header d-block">
            <div className="title">Type</div>
            <div>Image</div>
          </div>
          <div className="tab-section-header d-block">
            <div className="title">Size</div>
            <div>{formatFileSize(file.size)}</div>
          </div>
          <div className="tab-section-header d-block">
            <div className="title">Created</div>
            <div>{file.created_at}</div>
          </div>
          <div className="tab-section-header d-block">
            <div className="title">Modified</div>
            <div>{file.created_at}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileInformationTab;
