import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import PlusButton from "../../../common/plus-button";

const NoteComponent = ({ initialNote, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState(initialNote);

  const handleSave = () => {
    onSave(note);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  useEffect(()=>{
    setNote(initialNote)
  },[initialNote])
  return (
    <div className="note-component">
      <div className="tab-section-header no-select">
        <div className="title">Note</div>
        {!isEditing && note?.length != 0 && (
          <i className="material-icons icon-button" onClick={handleEdit}>
            edit
          </i>
        )}
      </div>
      {isEditing ? (
        <div>
          <Input
            type="textarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Type your note here..."
          />
          <Button
            color="primary"
            size="sm"
            onClick={handleSave}
            className="mt-2"
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="note-text">
          <React.Fragment>{note}</React.Fragment>
        </div>
      )}
      {!note && !isEditing && (
        <PlusButton label={"Add Note"} onClick={() => setIsEditing(true)} />
      )}
    </div>
  );
};

export default NoteComponent;
