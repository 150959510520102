// store.js
import { configureStore } from "@reduxjs/toolkit";
import activeFolderSlice from "./slices/active-folder-slice";
import favoritesSlice from "./slices/favorites-slice";
import foldersSlice from "./slices/folders-slice";
import organizationSlice from "./slices/organization-slice";
import searchSlice from "./slices/search-slice";
import trashSlice from "./slices/trash-slice";
import uploadSlice from "./slices/upload-slice";
import userPreferenceSlice from "./slices/user-preference-slice";
import userSlice from "./slices/user-slice";

const store = configureStore({
  reducer: {
    user: userSlice,
    organization: organizationSlice,
    activeFolder: activeFolderSlice,
    folders: foldersSlice,
    userPreference: userPreferenceSlice,
    upload: uploadSlice,
    search: searchSlice,
    favorites: favoritesSlice,
    trash: trashSlice,
  },
});

export default store;
