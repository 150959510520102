import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import NoteComponent from "./note-component";
import TagsComponent from "./tags-component";
import { updateImageTagsAndNotesAction } from "../../../../redux/slices/active-folder-slice";

const InsightsTab = ({ file, userId, orgId }) => {
  const [localNotes, setLocalNotes] = useState("");
  const [localTags, setLocalTags] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (file && file._doc) {
      setLocalNotes(file._doc.notes || "");
      setLocalTags(file._doc.tags || []);
    }
  }, [file]);

  const saveNote = (note) => {
    setLocalNotes(note);
    updateImageInfo(localTags, note);
  };

  const saveTags = (tags) => {
    setLocalTags(tags);
    updateImageInfo(tags, localNotes);
  };

  const updateImageInfo = (tags, notes) => {
    if (file && file._doc && file._doc._id) {
      const actionPayload = {
        imageId: file._doc._id,
        orgId: orgId,
        userId: userId,
        tags: tags,
        notes: notes,
      };
      dispatch(updateImageTagsAndNotesAction(actionPayload));
    } else {
    }
  };

  if (!file || !file._doc) {
    return <div>No file selected</div>;
  }

  return (
    <div className="tab-content">
      <NoteComponent initialNote={localNotes} onSave={saveNote} />
      <TagsComponent initialTags={localTags} onSave={saveTags} orgId={orgId} />
    </div>
  );
};

export default InsightsTab;
