import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const fetchFilesById = async (
  orgId,
  folderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/images/folder/${orgId}/${folderId}`,
      {
        method: "GET",
        headers: createHeaders(), // Use the headers function
      }
    );

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const uploadFile = async (
  file,
  userId,
  folderId,
  orgId,
  successHandler,
  errorHandler,
  handleProgress
) => {
  // Create a new FormData object
  const formData = new FormData();
  const tags = [];
  // Append the file and other metadata to the FormData object
  formData.append("image", file);
  formData.append("userId", userId);
  formData.append("folder_id", folderId);
  formData.append("org_id", orgId);
  // Append each tag individually
  tags.forEach((tag) => {
    formData.append("tags", tag); // Append each tag
  });
  formData.append("category", []);
  formData.append("notes", "");
  formData.append("name", file.name);

  // [
  //   ["image", base64data],
  //   ["orgId", "6623f91feb48977893e9691c"],
  //   ["userId", "66c9b0b61f747ada97cde0b6"],
  //   ["folderId", selectedFolderId],
  //   ["tags", currentTags.join(",")],
  //   ["category", currentCategories.join(",")],
  //   ["notes", currentNote || " "],
  //   ["name", fileName],
  // ]

  // Optional: log form data for debugging
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }

  const xhr = new XMLHttpRequest();

  xhr.open("POST", `${API_BASE_URL}/api/images/upload`, true); // Adjust the URL to your actual endpoint

  // Track upload progress
  xhr.upload.onprogress = function (event) {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      console.log("fffff", percentComplete);
      if (handleProgress) handleProgress(percentComplete);
      // setStatusMessage(`Upload Progress: ${percentComplete.toFixed(2)}%`);
    }
  };

  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      const response = JSON.parse(xhr.responseText);
      console.log("Upload successful:", response);
      successHandler(response);
    } else {
      console.error("Upload failed:", xhr.statusText);
      errorHandler("Erro");
    }
  };

  xhr.onerror = function () {
    console.error("Upload error:", xhr.statusText);
    errorHandler("Upload Error!");
  };

  // Send the request
  xhr.send(formData);

  // try {
  //   // Create a custom fetch function with progress tracking
  //   const response = await fetch(`${API_BASE_URL}/api/images/upload`, {
  //     method: "POST",
  //     body: formData,
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     onUploadProgress: (progressEvent) => {
  //       if (progressEvent.lengthComputable) {
  //         console.log("indisfsdfsdfsd");
  //         const progress = (progressEvent.loaded / progressEvent.total) * 100;
  //         handleProgress(progress);
  //       }
  //     },
  //   });

  //   // Apply middleware functions
  //   await handleErrors(response, errorHandler); // Check for HTTP errors
  //   const data = await parseJSON(response); // Parse JSON

  //   if (data && successHandler) {
  //     successHandler(data);
  //   }

  //   return data;
  // } catch (error) {
  //   console.log("errrrrrrrrrrrrrrrrrrrrr", error);
  //   if (errorHandler) {
  //     errorHandler(error);
  //   } else {
  //     throw error;
  //   }
  // }
};

// Helper function to handle fetch with progress tracking
const fetchWithProgress = async (url, options, handleProgress) => {
  const response = await fetch(url, {
    ...options,
    body: new ReadableStream({
      start(controller) {
        const reader = options.body.getReader();
        let bytesUploaded = 0;
        const contentLength = options.body.size;

        reader.read().then(function process({ done, value }) {
          if (done) {
            controller.close();
            return;
          }

          bytesUploaded += value.length;
          const progress = (bytesUploaded / contentLength) * 100;
          if (handleProgress) {
            handleProgress(progress);
          }

          controller.enqueue(value);
          return reader.read().then(process);
        });
      },
    }),
  });
  console.log("sfdfsdfdsfdsfs:", url);

  console.log(response);
  return response;
};

export const fetchFileById = async (
  orgId,
  fileId,
  lang,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/images/image-info/${orgId}/${fileId}/${lang}`,
      {
        method: "GET",
        headers: createHeaders(), // Use the headers function
      }
    );

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const updateImageTagsAndNotes = async (
  imageId,
  orgId,
  userId,
  tags,
  notes
) => {
  try {
    const payload = {
      imageId,
      org_id: orgId,
      userId,
      tags: tags || [],
      notes: notes || "",
    };

    const response = await fetch(`${API_BASE_URL}/api/images/update-image`, {
      method: "PUT",
      headers: createHeaders(),
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to update image");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetailsById = async (
  orgId,
  userId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/users/${orgId}/${userId}/name`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const updateImageName = async (
  imageId,
  newName,
  orgId,
  language,
  userId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/images/rename`, {
      method: "PUT",
      headers: createHeaders(),
      body: JSON.stringify({
        imageId,
        orgId,
        userId,
        name: newName,
        language,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const moveImage = async (
  imageId,
  orgId,
  folderId,
  userId,
  language,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/images/move-image`, {
      method: "PUT",
      headers: createHeaders(),
      body: JSON.stringify({
        imageId,
        orgId,
        folderId,
        userId,
        language,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const moveFileToTrashService = async (
  imageId,
  userId,
  orgId,
  language,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/images/bin/${imageId}`, {
      method: "PUT",
      headers: createHeaders(),
      body: JSON.stringify({
        userId,
        language,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const fetchImageFordownload = async (
  orgId,
  imageId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/images/download/${orgId}/${imageId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);

    const blob = await response.blob();

    if (blob && successHandler) {
      successHandler(blob);
    }
    return blob;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
