import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadFile } from "../services/file-service";
import { fetchFilesInFolder, fetchFolderContents } from "./active-folder-slice";

const initialState = {
  selectedFilesToUpload: [],
};

export const uploadFileAction = createAsyncThunk(
  "images/upload",
  async (
    { orgId, folderId, file, userId, handleProgress, fileId },
    { dispatch, getState }
  ) => {
    try {
      // let progress=5;
      // let interval= setInterval(()=>{
      //   progress=progress+5;
      //     if(progress>=80){
      //       clearInterval(interval)
      //     }else{
      //       dispatch(updateProgress({id:fileId,progress}))
      //     }
      // },500)
      const data = await uploadFile(
        file,
        userId,
        folderId,
        orgId,
        (data) => {
          if (data._id) {
            const { activeFolder } = getState();

            if (activeFolder.activeFolderId == folderId) {
              dispatch(fetchFilesInFolder({ orgId, folderId }));
            }

            // Return user data for updating the state
            dispatch(handleCompleteUpload({ id: fileId, isSuccess: true }));
          }
        },
        () => {
          dispatch(handleCompleteUpload({ id: fileId, isSuccess: false }));
        },
        (progress) =>
          dispatch(updateProgress({ id: fileId, progress: progress }))
      );
      
    } catch (error) {}
  }
);

function generateRandomHexID() {
  // Generate a random number between 0 and 16777215 (which is 0xFFFFFF)
  const randomNum = Math.floor(Math.random() * 16777216);

  // Convert the number to a hexadecimal string and pad with zeros if necessary
  const hexID = randomNum.toString(16).padStart(6, "0");

  return hexID.toUpperCase(); // Optional: Convert to uppercase for consistency
}

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setSelectedFilesToUpload: (state, action) => {
      // ToDo: @Dhiraj this is temp logic. Please modify as needed.
      //
      // Also, we are getting this error in console:
      // A non-serializable value was detected in the state, in the path: `upload.selectedFilesToUpload.0.file`.
      //
      // Reason I found:
      // The error you're seeing is due to Redux Toolkit's default middleware configuration, which checks for non-serializable values in the state. Typically, file objects (like File or Blob instances) are non-serializable and can trigger this warning.
      // Redux is designed to work best with serializable data. File objects, as they contain references to file data in memory, are not serializable. When you attempt to store such objects directly in the Redux state, the middleware raises a warning.
      // Solution:
      // Instead of storing the entire file object in the Redux state, store only serializable metadata, such as the file's name, size, and type. The file itself can be managed separately, for example, in a FormData object or directly through the DOM when uploading.

      const newFiles = action.payload.map((file) => ({
        file: file.file,
        id: generateRandomHexID(),
        isInQueue: true,
        isUploading: false,
        hasError: false,
        isUploaded: false,
        progress: 0,
        folderId: file.folderId,
      }));

      // Append new files to the existing ones
      state.selectedFilesToUpload = [
        ...state.selectedFilesToUpload,
        ...newFiles,
      ];
    },
    updateProgress: (state, action) => {
      console.log(action);

      let files = [...state.selectedFilesToUpload];
      let file = files.filter((file) => file.id == action.payload.id)[0];
      if (file) {
        file.progress = action.payload.progress;
        state.selectedFilesToUpload = [...files];
        console.log(action);
        console.log(state);
      }
    },
    handleCompleteUpload: (state, action) => {
      console.log(action);

      let files = [...state.selectedFilesToUpload];
      let file = files.filter((file) => file.id == action.payload.id)[0];
      if (file && action.payload.isSuccess) {
        file.isInQueue = false;
        file.isUploading = false;
        file.progress = 100;
        file.isUploaded = true;
        console.log("Fdffd", file);
        state.selectedFilesToUpload = [...files];
      } else {
        let files = [...state.selectedFilesToUpload];
        let file = files.filter((file) => file.id == action.payload.id)[0];
        file.hasError = true;
        file.isUploading = false;
        console.log("Fdffd", file);
        state.selectedFilesToUpload = [...files];
      }
    },

    startUpload: (state, action) => {
      let files = [...state.selectedFilesToUpload];
      let file = files.filter((file) => file.id == action.payload.id)[0];
      file.isInQueue = false;
      file.isUploading = true;
      console.log("Fdffd", file);
      state.selectedFilesToUpload = [...files];
    },
    clearSelectedFilesToUpload: (state) => {
      state.selectedFilesToUpload = [];
    },
    removeSelectedFile: (state, action) => {
      // ToDo: @Dhiraj This is temporary. I was not having unique id so doing like this.
      // Please update as needed.
      // Also, make sure to cancel the upload if the file upload process has started already.
      const fileToRemove = action.payload;
      const updatedList = state.selectedFilesToUpload.filter(
        (file) => file.file.path !== fileToRemove.file.path
      );
      state.selectedFilesToUpload = updatedList;
    },
  },
  extraReducers: (builder) => {
    // builder // ====================
    //   // Extra Reducers for : fetchActiveFolderBreadcrumb
    //   // ====================
    //   .addCase(uploadFileAction.pending, (state) => {
    //     // state.statusFetchActiveFolderBreadcrumb = API_STATUS.LOADING;
    //   })
    //   .addCase(uploadFileAction.fulfilled, (state, action) => {
    //     // Update the state with user data

    //     let files = [...state.selectedFilesToUpload];
    //     let file = files.filter((file) => file.id == action.payload.id)[0];
    //     if (action.payload.isSuccess) {
    //       file.isInQueue = false;
    //       file.isUploading = false;
    //       file.progress = 100;
    //       file.isUploaded = true;
    //       console.log("Fdffd", file);
    //       state.selectedFilesToUpload = [...files];
    //     } else {
    //       let files = [...state.selectedFilesToUpload];
    //       let file = files.filter((file) => file.id == action.payload.id)[0];
    //       file.hasError = true;
    //       file.isUploading = false;
    //       console.log("Fdffd", file);
    //       state.selectedFilesToUpload = [...files];
    //     }
    //   })
    //   .addCase(uploadFileAction.rejected, (state, action) => {
    //     let files = [...state.selectedFilesToUpload];
    //     let file = files.filter((file) => file.id == action.payload.id)[0];
    //     file.hasError = true;
    //     file.isUploading = false;
    //     console.log("Fdffd", file);
    //     state.selectedFilesToUpload = [...files];
    //     // ToDo: Error handling
    //   });
  },
});

export const {
  setSelectedFilesToUpload,
  removeSelectedFile,
  clearSelectedFilesToUpload,
  updateProgress,
  startUpload,
  handleCompleteUpload
} = uploadSlice.actions;

export const getSelectedFilesToUpload = (state) =>
  state.upload.selectedFilesToUpload;

export default uploadSlice.reducer;
