import React from "react";
import { Button } from "reactstrap";

const PlusButton = ({ label, onClick }) => {
  return (
    <>
      <Button color="link" className="plus-button" onClick={onClick}>
        <i className="material-icons">add_box</i>
        {label}
      </Button>
    </>
  );
};

export default PlusButton;
