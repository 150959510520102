import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function ProximaDropZone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    props.setSelectedFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
  });

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} />

      {isDragActive ? (
        <div class="dz-message">
          <span class="dz-message-text">
            <span>Drop </span>
            the file here
          </span>
        </div>
      ) : (
        <div class="dz-message">
          <span class="dz-message-text">
            <span>Drag and drop</span>
            file here or
            <span>browse</span>
          </span>
        </div>
      )}
    </div>
  );
}

export default ProximaDropZone;
