import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ImageViewerPanel from "./image-viewer-panel";
import ImageDetailsPanel from "./image-details-panel";

const ImageViewerModal = ({ isOpen, toggle, files, currentFileIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(currentFileIndex);
  const [currentFile, setCurrentFile] = useState(files[currentFileIndex]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Do not do anything when keyboard keys are pressed inside text areas
      const activeElement = document.activeElement;
      if (
        activeElement.tagName !== "INPUT" &&
        activeElement.tagName !== "TEXTAREA"
      ) {
        if (e.key === "ArrowRight") {
          handleNext();
        } else if (e.key === "ArrowLeft") {
          handlePrevious();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    setCurrentFile(files[currentIndex]);
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  const handleNext = () => {
    if (currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="image-viewer-modal"
      fullscreen
    >
      <ModalHeader
        className="d-flex justify-content-between align-items-center"
        toggle={toggle}
      >
        {currentFile.name}
      </ModalHeader>
      <ModalBody>
        {/* Left Panel */}
        <ImageViewerPanel
          files={files}
          currentFile={currentFile}
          currentIndex={currentIndex}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
        {/* Right Panel */}
        <ImageDetailsPanel currentFile={currentFile} />
      </ModalBody>
    </Modal>
  );
};

export default ImageViewerModal;
