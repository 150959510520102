import React from "react";
import { Button, FormGroup, Input } from "reactstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ReaderSettingsPanel = ({
  showOwner,
  setShowOwner,
  showTags,
  setShowTags,
  showNote,
  setShowNote,
  pageRef,
}) => {
  const handleDownload = async () => {
    const element = pageRef.current;
    const children = Array.from(element.children);
    const pdf = new jsPDF("p", "px");
    let pageWidth = pdf.internal.pageSize.width;
    let pageHeight = pdf.internal.pageSize.height;
    let yOffset = 20;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const canvas = await html2canvas(child, {
        scale: 2,
        useCORS: true,
      });

      let imgWidth = canvas.width;
      let imgHeight = canvas.height;
      const aspectRatio = imgWidth / imgHeight;

      if (imgWidth > pageWidth - 40) {
        imgWidth = pageWidth - 40;
        imgHeight = imgWidth / aspectRatio;
      }

      if (yOffset + imgHeight > pageHeight - 20) {
        pdf.addPage();
        yOffset = 20;
      }

      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        20,
        yOffset,
        imgWidth,
        imgHeight
      );

      yOffset += imgHeight + 20;

      if (i % 2 === 0 && i + 1 < children.length) {
        const nextCanvas = await html2canvas(children[i + 1], {
          scale: 2,
          useCORS: true,
        });
        const nextImgHeight =
          (nextCanvas.height * (pageWidth - 40)) / nextCanvas.width;

        if (yOffset + nextImgHeight > pageHeight - 20) {
          pdf.addPage();
          yOffset = 20;
        }
      }
    }

    pdf.save("download.pdf");
  };

  return (
    <>
      <div className="reader-mode-settings-container no-select">
        <div className="rm-settings-header">Display Settings</div>

        <div className="rm-settings-controls-container">
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showOwner}
              onChange={() => setShowOwner(!showOwner)}
            />
            <div className="switch-label">File Owner</div>
          </FormGroup>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showTags}
              onChange={() => setShowTags(!showTags)}
            />
            <div className="switch-label">Tags</div>
          </FormGroup>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showNote}
              onChange={() => setShowNote(!showNote)}
            />
            <div className="switch-label">Note</div>
          </FormGroup>
        </div>

        <div>
          <Button color="primary" onClick={handleDownload}>
            Download
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReaderSettingsPanel;
