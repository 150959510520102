import React, { useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { API_BASE_URL } from "../../../redux/services/base-url";
import FileContextMenu from "../menu/file-context-menu";

const FileThumbnailItem = ({ file, userId, onClick, onDoubleClick }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  return (
    <>
      <Card
        className="file-thumbnail-item no-select"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <CardBody className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center w-100">
            <i className="material-icons file-icon">image</i>
            <CardTitle className="mb-0 file-name">{file.name}</CardTitle>
            <FileContextMenu
              file={file}
              userId={userId}
              onFileOpen={onDoubleClick}
            />
          </div>
          <div className="image-preview mt-2">
            {!isImageLoaded && (
              <div className="image-placeholder">
                <i className="material-icons">image</i>
              </div>
            )}
            <img
              src={`${API_BASE_URL}/api/images/thumbnail/${file._id}`}
              alt={file.name}
              draggable="false"
              onLoad={handleImageLoad}
              style={{
                display: isImageLoaded ? "block" : "none",
              }}
            />
            {!file.is_OCR ? (
              <div className="image-processing-overlay">
                <div class="splash-animation"></div>Processing Image
              </div>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FileThumbnailItem;
