import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import routes from "../../layout/routes/routes";
import {
  moveFolderAction,
  moveImageAction,
} from "../../redux/slices/active-folder-slice";
import {
  fetchSharedStorageFolderHierarchy,
  getMyStorageFolderHierarchy,
  getSharedStorageFolderHierarchy,
} from "../../redux/slices/folders-slice";
import FolderTreeNode from "./folder-tree-node";

export const FOLDER_TREE_SELECTION_MODES = {
  MOVE_FOLDER: "move-folder",
  COPY_FOLDER: "copy-folder",
  MOVE_FILE: "move-file",
  COPY_FILE: "copy-file",
  DEFAULT: "default",
};

const STORAGE_TYPES = {
  SHARED_STORAGE: "shared-storage",
  MY_STORAGE: "my-storage",
};

const FolderTreeSelectionModal = ({
  isOpen,
  toggle,
  onSubmit,
  type = FOLDER_TREE_SELECTION_MODES.DEFAULT,
  item,
  userId,
}) => {
  const dispatch = useDispatch();

  const sharedStorageRoute = routes.find(
    (route) => route.id === "SHARED_STORAGE"
  );
  const myStorageRoute = routes.find((route) => route.id === "MY_STORAGE");
  const storageType =
    item.is_user_folder || item.is_user_file
      ? STORAGE_TYPES.MY_STORAGE
      : STORAGE_TYPES.SHARED_STORAGE;

  const [folderData, setFolderData] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const organization = useSelector((state) => state.organization);
  const folderHierarchy = useSelector(
    storageType === STORAGE_TYPES.SHARED_STORAGE
      ? getSharedStorageFolderHierarchy
      : getMyStorageFolderHierarchy
  );

  useEffect(() => {
    if (isOpen && organization?.id?.length > 0) {
      setSelectedFolder(null); // Clear selected folder every time when the modal is opened
      if (storageType === STORAGE_TYPES.SHARED_STORAGE)
        dispatch(fetchSharedStorageFolderHierarchy(organization.id));
    }
  }, [isOpen, storageType, organization.id]);

  useEffect(() => {
    // Change name of root as per routes
    const modifiedHierarchy = {
      ...folderHierarchy,
      name:
        storageType === STORAGE_TYPES.SHARED_STORAGE
          ? sharedStorageRoute.name
          : myStorageRoute.name,
    };
    setFolderData(modifiedHierarchy);
  }, [folderHierarchy]);

  const handleSelect = (folder) => {
    console.log("Selected Folder - " + folder.name);
    if (
      type === FOLDER_TREE_SELECTION_MODES.MOVE_FILE ||
      type === FOLDER_TREE_SELECTION_MODES.COPY_FILE
    ) {
      // Do not allow root folder selection if moving or copying files
      if (folder._id != "root") {
        setSelectedFolder(folder);
      }
    } else {
      setSelectedFolder(folder);
    }
  };

  const handleSubmit = () => {
    console.log("Submitted Folder - " + selectedFolder.name);
    // For move and copy, handle the operations inside the modal itself.
    if (selectedFolder) {
      if (type === FOLDER_TREE_SELECTION_MODES.MOVE_FOLDER) {
        dispatch(
          moveFolderAction({
            folderId: item._id,
            newParentFolderId:
              selectedFolder._id === "root" ? null : selectedFolder._id,
            successHandler: () => {
              toggle();
            },
          })
        );
      } else if (type === FOLDER_TREE_SELECTION_MODES.COPY_FOLDER) {
        // ToDo: Dispatch copy folder action here
      } else if (type === FOLDER_TREE_SELECTION_MODES.MOVE_FILE) {
        if (selectedFolder._id !== "root") {
          dispatch(
            moveImageAction({
              imageId: item._id,
              orgId: item.org_id,
              folderId: selectedFolder._id,
              userId: userId,
              language: item.language || item.lang,
              successHandler: () => {
                toggle();
              },
            })
          );
        }
      } else if (type === FOLDER_TREE_SELECTION_MODES.COPY_FILE) {
        // ToDo: Dispatch copy file action here
      } else {
        // Submit selected folder to handle actions outside the modal
        if (onSubmit) {
          onSubmit(selectedFolder);
        }
        toggle();
      }
    }
  };

  // Function to check if a folder should be disabled
  const isDisabled = (folder) => {
    if (
      type === FOLDER_TREE_SELECTION_MODES.MOVE_FOLDER &&
      folder._id === item._id
    ) {
      return true; // Disable if the folder is the one being moved or any of its descendants
    }
    return false;
  };

  const isSelected = (folder) => {
    if (folder._id === selectedFolder?._id) {
      return true;
    } else return false;
  };

  const handleDoubleClick = (event) => {
    // Preventing event bubbling which was causing any double clicks on this modal trigger double click of parent folder/file item
    event.stopPropagation();
  };

  const renderModalTitle = () => {
    switch (type) {
      case FOLDER_TREE_SELECTION_MODES.MOVE_FOLDER:
      case FOLDER_TREE_SELECTION_MODES.MOVE_FILE:
        return `Move "${item.name || item.folder_name}"`;
      case FOLDER_TREE_SELECTION_MODES.COPY_FOLDER:
      case FOLDER_TREE_SELECTION_MODES.COPY_FILE:
        return `Copy "${item.name || item.folder_name}"`;
      default:
        return "Select folder";
    }
  };

  const renderSubmitLabel = () => {
    switch (type) {
      case FOLDER_TREE_SELECTION_MODES.MOVE_FOLDER:
      case FOLDER_TREE_SELECTION_MODES.MOVE_FILE:
        return "Move";
      case FOLDER_TREE_SELECTION_MODES.COPY_FOLDER:
      case FOLDER_TREE_SELECTION_MODES.COPY_FILE:
        return "Copy";
      default:
        return "Select";
    }
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal no-select"
      onDoubleClick={handleDoubleClick}
    >
      <ModalHeader toggle={toggle}>{renderModalTitle()}</ModalHeader>
      <ModalBody className="p-0">
        <div className="folder-tree-container">
          {folderData && (
            <FolderTreeNode
              folder={folderData}
              onSelect={handleSelect}
              isDisabled={isDisabled}
              isSelected={isSelected}
              storageType={storageType}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedFolder}
        >
          {renderSubmitLabel()}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FolderTreeSelectionModal;
