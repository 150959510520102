import React from "react";
import { Input, ListGroup, ListGroupItem } from "reactstrap";

const InputWithSuggestions = ({
  currentInput,
  handleInputChange,
  handleInputKeyPress,
  placeholderText,
  suggestions,
  handleSuggestionClick,
}) => {
  return (
    <div className="input-with-suggestions">
      <Input
        type="text"
        value={currentInput}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyPress}
        placeholder={placeholderText}
      />
      {suggestions?.length > 0 && (
        <ListGroup className="suggestions-dropdown dropdown-menu">
          {suggestions.map((suggestion, index) => (
            <ListGroupItem
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              {suggestion}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default InputWithSuggestions;
