import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const searchFiles = async (filters, successHandler, errorHandler) => {
  try {
    if (filters.tags.length == 1 && filters.tags[0] === "") {
      filters.tags = [];
    }
    const response = await fetch(`${API_BASE_URL}/api/images/search`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify(filters),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data) {
      if (successHandler) {
        successHandler(data);
      }
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const searchFilesByTags = async (
  orgId,
  searchKey,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/search-by-tags`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify({
        orgId,
        searchKey,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const fetchTags = async (orgId, successHandler, errorHandler) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/tags/fetch?org_id=${orgId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data.tags);
    }
    return data.tags;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
