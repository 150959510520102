import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../constants/api-status";
import { searchFiles, fetchTags } from "../services/search-service";
import { renameFile, renameFolder } from "./active-folder-slice";
import { moveFileToTrashAction, moveFolderToTrashAction } from "./trash-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";

const initialState = {
  files: [],
  folders: [],
  notes: [],
  tagSuggestions: [],
  status: "",
  error: null,
};

export const searchFilesAction = createAsyncThunk(
  "searchs/search-file",
  async ({ filters, successHandler }, { dispatch }) => {
    const data = await searchFiles(filters, successHandler);
    return data; // Return user data for updating the state
  }
);

export const fetchTagsAction = createAsyncThunk(
  "searchs/fetch-tags",
  async (orgId, { dispatch }) => {
    const tags = await fetchTags(orgId);
    return tags;
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setResult: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for : fetchFolders
      // ====================
      .addCase(searchFilesAction.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(searchFilesAction.fulfilled, (state, action) => {
        // Update the state with user data

        state.files = action.payload.files;
        state.folders = action.payload.folders;
        // state = { ...state, ...action.payload };
        state.status = API_STATUS.SUCCESS;
      })
      .addCase(searchFilesAction.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = API_STATUS.ERROR;
        // ====================
        // Extra Reducers for : fetchTags
        // ====================
      })
      .addCase(fetchTagsAction.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTagsAction.fulfilled, (state, action) => {
        state.tagSuggestions = action.payload;
        state.status = API_STATUS.SUCCESS;
      })
      .addCase(fetchTagsAction.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = API_STATUS.ERROR;
      })

      // ====================
      // Extra Reducers for : Rename
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        state.folders = state.folders.map((folder) =>
          folder._id === action.payload.folderId
            ? { ...folder, folder_name: action.payload.newName }
            : folder
        );
      })
      .addCase(renameFile.fulfilled, (state, action) => {
        state.files = state.files.map((file) =>
          file._id === action.payload.fileId
            ? { ...file, name: action.payload.newName }
            : file
        );
      })

      // ====================
      // Extra Reducers for favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;

        // Add to files
        if (itemType && itemType === "image") {
          state.files = state.files.map((file) =>
            file._id === itemId ? { ...file, is_favorite: true } : file
          );
        }
        // Add to folders
        else if (itemType && itemType === "folder") {
          state.folders = state.folders.map((folder) =>
            folder._id === itemId ? { ...folder, is_favorite: true } : folder
          );
        }
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;

        // Remove from files
        if (itemType && itemType === "image") {
          state.files = state.files.map((file) =>
            file._id === itemId ? { ...file, is_favorite: false } : file
          );
        }
        // Remove from folders
        else if (itemType && itemType === "folder") {
          state.folders = state.folders.map((folder) =>
            folder._id === itemId ? { ...folder, is_favorite: false } : folder
          );
        }
      })

      // ====================
      // Extra Reducers for Trash
      // ====================
      .addCase(moveFolderToTrashAction.fulfilled, (state, action) => {
        state.folders = state.folders.filter(
          (folder) => folder._id !== action.payload.folder._id
        );
      })
      .addCase(moveFileToTrashAction.fulfilled, (state, action) => {
        state.files = state.files.filter(
          (file) => file._id !== action.payload.file._id
        );
      });
  },
});

export const { setResult } = searchSlice.actions;

// export const getStatusFetchFolders = (state) => state.folders.status;

export const getSeachResultFiles = (state) => state.search.files;
export const getSeachResultfolders = (state) => state.search.folders;
export const getTagSuggestions = (state) => state.search.tagSuggestions;

export default searchSlice.reducer;
