import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ReaderModePagePanel from "./reader-mode-page-panel";
import ReaderModeSettingsPanel from "./reader-mode-settings-panel";

const ReaderModeModal = ({ isOpen, toggle, files }) => {
  // State to manage switches
  const [showOwner, setShowOwner] = useState(true);
  const [showTags, setShowTags] = useState(true);
  const [showNote, setShowNote] = useState(true);
  const pageRef = useRef(null)
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="reader-mode-modal"
      fullscreen
    >
      <ModalHeader
        className="d-flex justify-content-between align-items-center"
        toggle={toggle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#212529"
          style={{ marginTop: "-3px" }}
        >
          <path d="M240-80q-50 0-85-35t-35-85v-120h120v-560h600v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-600H320v480h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h360v80H360Zm0 120v-80h360v80H360ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm0 0h-40 400-360Z" />
        </svg>{" "}
        Reader Mode
      </ModalHeader>
      <ModalBody>
        {/* Left Panel */}
        <ReaderModePagePanel
          files={files}
          showOwner={showOwner}
          showTags={showTags}
          showNote={showNote}
          pageRef={pageRef}
        />
        {/* Right Panel */}
        <ReaderModeSettingsPanel
          showOwner={showOwner}
          setShowOwner={setShowOwner}
          showTags={showTags}
          setShowTags={setShowTags}
          showNote={showNote}
          setShowNote={setShowNote}
          pageRef={pageRef}
        />
      </ModalBody>
    </Modal>
  );
};

export default ReaderModeModal;
