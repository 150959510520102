import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import InputWithSuggestions from "../../../common/input-with-suggestions";
import TagsList from "../../../common/tags-list";
import PlusButton from "../../../common/plus-button";
import {
  fetchTagsAction,
  getTagSuggestions,
} from "../../../../redux/slices/search-slice";

const TagsComponent = ({ initialTags, onSave, orgId }) => {
  const dispatch = useDispatch();
  const suggestions = useSelector(getTagSuggestions);
  const [tags, setTags] = useState(initialTags || []);
  const [isEditing, setIsEditing] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  useEffect(() => {
    if(initialTags){
      setTags(initialTags)
    }
     
    else{
      setTags([])
    }
  }, [initialTags]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchTagsAction(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (currentInput.trim() !== "") {
      const newFilteredSuggestions = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(currentInput.toLowerCase())
      );
      setFilteredSuggestions(newFilteredSuggestions);
    } else {
      setFilteredSuggestions([]);
    }
  }, [currentInput, suggestions]);

  const handleSuggestionClick = (suggestion) => {
    if (!tags.includes(suggestion)) {
      setTags([...tags, suggestion]);
    }
    setCurrentInput("");
    setFilteredSuggestions([]);
  };

  const handleInputChange = (e) => {
    setCurrentInput(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const trimmedInput = currentInput.trim();
      if (trimmedInput !== "" && !tags?.includes(trimmedInput)) {
        setTags([...tags, trimmedInput]);
      }
      setCurrentInput("");
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleClearAll = () => {
    setTags([]);
  };

  const handleSave = () => {
    const trimmedInput = currentInput.trim();
    if (trimmedInput !== "" && !tags?.includes(trimmedInput)) {
      const updatedTags = [...tags, trimmedInput];
      setTags(updatedTags);
      onSave(updatedTags);
    } else {
      onSave(tags); // If no new tag is added, just save the current tags
    }
    setCurrentInput("");
    setIsEditing(false);
  };

  return (
    <div className="tags-component">
      <div className="tab-section-header no-select">
        <div className="title">Tags</div>
        {!isEditing && tags?.length != 0 && (
          <i className="material-icons icon-button" onClick={handleEdit}>
            edit
          </i>
        )}
      </div>
      <TagsList
        tags={tags}
        isEditing={isEditing}
        onRemove={handleTagRemove}
        select={false}
      />
      {isEditing && (
        <div className="mt-2">
          <InputWithSuggestions
            placeholderText={"Type tags separated by commas"}
            currentInput={currentInput}
            handleInputChange={handleInputChange}
            handleInputKeyPress={handleInputKeyPress}
            suggestions={filteredSuggestions}
            handleSuggestionClick={handleSuggestionClick}
          />
          <Button
            color="primary"
            size="sm"
            onClick={handleSave}
            className="mt-2"
          >
            Save
          </Button>
          {tags?.length > 0 && (
            <Button color="link" onClick={handleClearAll} className="mt-2">
              Clear all
            </Button>
          )}
        </div>
      )}
      {!isEditing && tags?.length == 0 && (
        <PlusButton label={"Add Tags"} onClick={() => setIsEditing(true)} />
      )}
    </div>
  );
};

export default TagsComponent;
