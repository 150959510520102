import React from "react";
import { API_BASE_URL } from "../../../../redux/services/base-url";
import CanvasEditor from "./image-canvas";

const ImageViewerPanel = ({
  files,
  currentFile,
  currentIndex,
  handleNext,
  handlePrevious,
}) => {
  return (
    <div className="image-viewer-left no-select ">
      {currentIndex > 0 && (
        <div className="navigate-button left" onClick={handlePrevious}>
          <i className="material-icons">chevron_left</i>
        </div>
      )}
      <div className="image-container">
        <img
          src={`${API_BASE_URL}/api/images/image/${currentFile?.name}/${currentFile?.org_id}`}
          alt={currentFile.name}
          className="image-view"
          draggable={false}
        />
        {/* <CanvasEditor url ={`${API_BASE_URL}/api/image/${currentFile?.name}/${currentFile?.org_id}/${currentFile?.folder_id}`}/> */}
      </div>
      {currentIndex < files.length - 1 && (
        <div className="navigate-button right" onClick={handleNext}>
          <i className="material-icons">chevron_right</i>
        </div>
      )}
      {/* <div style={{ position: "fixed", bottom: 0 }}>
        Placehoder for bottom panel
      </div> */}
    </div>
  );
};

export default ImageViewerPanel;
